import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from 'store/UrlUtils';
import {
  FEL_FORMATIONS, FEL_RECEVOIR_FORMATION, FEL_INSCRIPTION, FEL_FORMATION, FEL_DOCUMENT, FEL_SECTION, 
  FEL_ADD_INSCRIPTION_DE_FORMATION, FEL_ADD_EXAMEN_DE_FORMATION, FEL_UPDATE_INFO_EXAMEN_DE_FORMATION,
  FEL_INFO_EXAMEN, FEL_QUESTION_EXAMEN, FEL_REPONSE_AUX_QUESTIONS_EXAMEN, FEL_QUESTION_EXAMEN_FICHIER_JOINT,
  FEL_UPDATE_REPONSE_AUX_QUESTIONS_EXAMEN,
  FEL_RAPPORT_ATTESTATION, FEL_RAPPORT_CALENDRIER_ACTIVITE, 
  FCR_GET_EXAMEN_CORRIGE_RAPORT
} from 'store/UrlConstants';
import { showSnackbarMessage } from '../MessagesSystemSlice';

const formationsAdapter = createEntityAdapter({
  selectId: entity => entity.formationId
});

export const fetchFormations = createAsyncThunk('FormationEnLigne/FormationsRegister', async ({ action, sites, ville }) => {
  const path = action === 'InscrireUneFormation' ? FEL_FORMATIONS : FEL_RECEVOIR_FORMATION
  const response = await axios.get(path + listToQueryParam("siteCodes", sites) + (ville ? paramsToQueryParam({city: ville}).replace('?','&') : "") );
  return response.data; 
});

export const loadFormationInscription = createAsyncThunk("FormationEnLigne/FormationInscription", async ({idFormation, employeNo = null}) => {
  const response = await axios.get(FEL_INSCRIPTION + paramsToQueryParam({ idFormation, ...(employeNo && { employeNo }) }));
  return response.data;
})

export const loadFormation = createAsyncThunk('FormationEnLigne/Formation', async ({idFormation, employeNo = null}) => {
  const f = await axios.get(FEL_FORMATION + paramsToQueryParam({ idFormation, ...(employeNo && { employeNo }) }));
  const s = await axios.get(FEL_SECTION + paramsToQueryParam({ idFormation, ...(employeNo && { employeNo }) }));
  const d = await axios.get(FEL_DOCUMENT + paramsToQueryParam({ idFormation, ...(employeNo && { employeNo }) }));
  return { info: f.data, sections: s.data, documents: d.data }
});

export const loadFormationDocument = createAsyncThunk('FormationEnLigne/FormationDocument', async (idFormation) => {
  const response = await axios.get(FEL_DOCUMENT + paramsToQueryParam({ idFormation }));
  return response.data;
});

// Il doit recevoir comme paramètre: objet[{formationId = 0, noEmploye[""], dateInscription = "ISO String"}]
export const addFormationInscription = createAsyncThunk("FormationEnLigne/AddFormationInscription", async (employesForInscription = {}, thunkAPI) => {
  try {
    const response = await axios.post(FEL_ADD_INSCRIPTION_DE_FORMATION, employesForInscription);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch(error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

export const addFormationExamen = createAsyncThunk("FormationEnLigne/AddFormationExamen", async (formationExamen = {}) => {
  const response = await axios.post(FEL_ADD_EXAMEN_DE_FORMATION, formationExamen);
  return response.data;
})

export const UpdateFormationExamenInfo = createAsyncThunk("FormationEnLigne/UpdateFormationExamenInfo", async (formationExamenInfo = {}) => {
  const response = await axios.post(FEL_UPDATE_INFO_EXAMEN_DE_FORMATION, formationExamenInfo);
  return response.data;
})

export const loadExamenInfo = createAsyncThunk('FormationEnLigne/loadExamenInfo', async ({noExamen, employeNo = null}) => {
  const response = await axios.get(FEL_INFO_EXAMEN + paramsToQueryParam({ noExamen, ...(employeNo && { employeNo }) }));
  return response.data;
});

export const loadExamenQuestion = createAsyncThunk("FormationEnLigne/loadExamenQuestion", async ({noExamen, employeNo = null}) => {
  const response = await axios.get(FEL_QUESTION_EXAMEN + paramsToQueryParam({ noExamen, ...(employeNo && { employeNo }) }));
  return response.data;
})

export const loadExamenQuestionReponse = createAsyncThunk("FormationEnLigne/loadExamenQuestionReponse", async ({questionId, employeNo = null}) => {
  const response = await axios.get(FEL_REPONSE_AUX_QUESTIONS_EXAMEN + paramsToQueryParam({ questionId, ...(employeNo && { employeNo }) }));
  return response.data;
})

export const loadExamenQuestionFichierJoint = createAsyncThunk("FormationEnLigne/loadExamenQuestionFichierJoint", async ({questionId, employeNo = null}) => {
  const response = await axios.get(FEL_QUESTION_EXAMEN_FICHIER_JOINT + paramsToQueryParam({ questionId, ...(employeNo && { employeNo }) }));
  return response.data;
})

export const UpdateExamenQuestionReponse = createAsyncThunk("FormationEnLigne/UpdateExamenQuestionReponse", async (reponses = []) => {
  const response = await axios.post(FEL_UPDATE_REPONSE_AUX_QUESTIONS_EXAMEN, reponses);
  return response.data;
})

export const loadExamenRapport = createAsyncThunk("FormationEnLigne/loadExamReport", async (noExamen) => {
  const response = await axios.get(FCR_GET_EXAMEN_CORRIGE_RAPORT + paramsToQueryParam({ noExamen: noExamen }));
  return response.data;
})

export const loadAttestationRapport = createAsyncThunk("FormationEnLigne/loadAttestationtRapport", async ({idFormation, noEmploye}) => {
  const response = await axios.get(FEL_RAPPORT_ATTESTATION + paramsToQueryParam({ idFormation: idFormation, noEmploye }));
  return response.data;
})

export const loadCalendrierActiviteRapport = createAsyncThunk("FormationEnLigne/loadCalendrierActiviteRapport", async ({idFormation}) => {
  const response = await axios.get(FEL_RAPPORT_CALENDRIER_ACTIVITE + paramsToQueryParam({ idFormation: idFormation }));
  return response.data;
})

const initialState = formationsAdapter.getInitialState(
  {
    selectedFormation: {},
    formation: { loaded: false, info: {}, sections: [], documents: [] },
    examen: {noExamen: null, debutExamenPermis:null, debutExamen: null, resultat:null, maximum:null, reussi:null,
             info:{}, questions:[], reponses:[], fichiersJoints:[] },
    rapports: {examen:{}, attestation:{}, calendrierActivite:[]},
    status: 'idle'
  }
);

export const {
  selectAll: selectAllFormations,
  selectById: selectFormationById,
} = formationsAdapter.getSelectors((state) => state.felFormations);

const formationsSlice = createSlice({
  name: 'felFormations',
  initialState,
  reducers: {
    setFormationDocuments(state, action) {
      state.formation.documents = action.payload.map(row => ({...row}));
    },
    setNoExamen(state, action) {
      state.examen.noExamen = action.payload;
    },    
    setDebutExamenPermis(state, action) {
      state.examen.debutExamenPermis = action.payload;
    },
    setDebutExamen(state, action) {
      state.examen.debutExamen = action.payload;
    },
    setResultaExamen(state, action) {
      state.examen.resultat  = action.payload.resultat;
      state.examen.maximum  = action.payload.maximum;
      state.examen.reussi = action.payload.reussi;
    },
    resetStatus(state, action) {
      state.status = initialState.status;
    },
    setSections(state, action) {
      state.formation.sections = action.payload;
    },
  },
  extraReducers: {
    [fetchFormations.fulfilled]: (state, action) => {
      formationsAdapter.setAll(state, action.payload);
    },
    [loadFormationInscription.fulfilled]: (state, action) => {
      state.selectedFormation = action.payload;
    },
    [loadFormation.pending]: (state, action) => {
      state.formation = initialState.formation;
      state.examen = initialState.examen;
      state.status = 'loading';
    },    
    [loadFormation.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.formation.info = action.payload.info;
      state.formation.sections = action.payload.sections;
      state.formation.documents = action.payload.documents;
      state.formation.loaded = true;
    },
    [addFormationInscription.fulfilled]: (state, action) => {
      state.status = "addFormationInscriptionSucceeded";
    },
    [addFormationInscription.rejected]: (state, action) => {
      state.status = "addFormationInscriptionRejected";
    },
    [addFormationExamen.fulfilled]: (state, action) => {
      state.examen.noExamen = action.payload
    },
    [loadExamenInfo.fulfilled]: (state, action) => {
      state.examen.info = action.payload
    },
    [loadExamenQuestion.fulfilled]: (state, action) => {
      state.examen.questions = action.payload
    },
    [loadExamenQuestionReponse.fulfilled]: (state, action) => {
      state.examen.reponses = action.payload
    },
    [loadExamenQuestionFichierJoint.fulfilled]: (state, action) => {
      state.examen.fichiersJoints = action.payload
/*
      state.examen.fichiersJoints = action.payload.map(f => ({
        type: f.typeLien, 
        cle: f.typeLien === 'DO' ? f.docGedNomFichier : f.typeLien ===  'FJ' ? f.gedNomFichier : f.lien,
        nom: f.typeLien === 'DO' ? f.docNomFichier : f.lien,
        titre: f.typeLien === 'DO' ? f.dosTitre : f.description, 
      }))
*/
    },
    [loadExamenRapport.fulfilled]: (state, action) => {
      state.rapports.examen = action.payload
    },
    [loadAttestationRapport.fulfilled]: (state, action) => {
      state.rapports.attestation = action.payload
    },
    [loadCalendrierActiviteRapport.fulfilled]: (state, action) => {
      state.rapports.calendrierActivite = action.payload
    },

    [addFormationExamen.rejected]: (state, action) => {
      alert('addFormationExamen.rejected');
      alert(JSON.stringify(action.payload));
    },    
  },
});

export const { setFormationDocuments, setNoExamen, setDebutExamenPermis, setDebutExamen, setResultaExamen,
               resetStatus, setSections } = formationsSlice.actions;

export default formationsSlice.reducer;
