import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
    ADD_MAINT_MULTILANGUE_TRADUCTION, UPDATE_MAINT_MULTILANGUE_DESCRIPTION_TRADUITE,
    ADD_SITE_ASSOCIE, ADD_SITES_ASSOCIES, DELETE_SITE_ASSOCIE, DELETE_SITES_ASSOCIES,
} from "store/UrlConstants";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";


/* ======================================================================= */
// MaintMultilangueTraduction
/* ======================================================================= */
export const addMaintMultilangueTraductions = createAsyncThunk("MaintenanceGeneral/MaintMultilangueTraduction/Add", async (maintMultilangueTraduction, thunkAPI) => {
    try {
        const response = await axios.post(ADD_MAINT_MULTILANGUE_TRADUCTION, maintMultilangueTraduction);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        const originalMessage = error.response.data;
        const cutIndex = originalMessage.indexOf("executed:")
        const msg = originalMessage.slice(cutIndex, originalMessage.length).replace("executed: ", "")

        thunkAPI.dispatch(
            showSnackbarMessage({
                message: cutIndex ? msg : error.message,
                severity: "error",
            })
        );
    }
});

export const updateMaintMultilangueTraduction = createAsyncThunk("MaintenanceGeneral/MaintMultilangueTraduction/Update", async (maintMultilangueTraduction, thunkAPI) => {
    try {
        const response = await axios.put(UPDATE_MAINT_MULTILANGUE_DESCRIPTION_TRADUITE, maintMultilangueTraduction);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { updated: response.data, maintMultilangueTraductionUpdated: maintMultilangueTraduction };

    } catch (error) {

        const originalMessage = error.response.data;
        const cutIndex = originalMessage.indexOf("executed:")
        const msg = originalMessage.slice(cutIndex, originalMessage.length).replace("executed: ", "")

        thunkAPI.dispatch(
            showSnackbarMessage({
                message: cutIndex ? msg : error.message,
                severity: "error",
            })
        );
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


/* ======================================================================= */
// SitesAssocies
/* ======================================================================= */
// maintSiteAssocie = { siteId, maintSiteId }
export const addSiteAssocie = createAsyncThunk("MaintenanceGeneral/SitesAssocies/Add", async (maintSiteAssocie, thunkAPI) => {
    try {
        const response = await axios.post(ADD_SITE_ASSOCIE, maintSiteAssocie);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// maintSitesAssocies = [{ siteId, maintSiteId }, ...]
export const addSitesAssocies = createAsyncThunk("MaintenanceGeneral/SitesAssocies/AddList", async (maintSitesAssocies, thunkAPI) => {
    try {
        const response = await axios.post(ADD_SITES_ASSOCIES, maintSitesAssocies);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// maintSitesAssocies = { siteId, maintSiteId }
export const deleteSiteAssocie = createAsyncThunk("MaintenanceGeneral/SitesAssocies/Delete", async (maintSitesAssocies, thunkAPI) => {
    try {
        const response = await axios.delete(DELETE_SITE_ASSOCIE, { params: maintSitesAssocies });

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { deleted: response.data, siteId: maintSitesAssocies.siteId };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const deleteSitesAssocies = createAsyncThunk("MaintenanceGeneral/SitesAssocies/DeleteList", async (maintSitesAssocies, thunkAPI) => {
    const response = await axios.delete(DELETE_SITES_ASSOCIES, { data: maintSitesAssocies });

    try {
        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { deleted: response.data, maintSitesAssocies: maintSitesAssocies };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});



/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialState = {
    sitesApplicablesAjout: []
}

const maintenanceGeneralSlice = createSlice({
    name: "MaintenanceGeneral",
    initialState,
    reducers: {
        setSitesApplicablesAjout(state, action) {
            state.sitesApplicablesAjout = action.payload;
        }
    },
    extraReducers: {},
});

export const {
    setSitesApplicablesAjout
} = maintenanceGeneralSlice.actions;

export default maintenanceGeneralSlice.reducer;