import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "Services/AxiosService";
import {showSnackbarMessage} from "./MessagesSystemSlice"

// Axios query wrapper qui utilise notre instance
const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: "" }) =>
    async ({ url, method, data, params, headers, showErrorSnackbar = false }, {dispatch}) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
            });
            return { data: result.data };
        } catch (axiosError) {
            if(showErrorSnackbar) {
                const msg = axiosError.response?.data?.message || axiosError.response?.data?.messageText || "Erreur inconnue"
                dispatch(showSnackbarMessage({
                    message: msg,
                    severity: "error",
                    autoHideDuration: 10000,
                }));
            }

            let err = axiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

// Define our single API slice object
export const emptySplitApi = createApi({
    // All of our requests will have URLs starting with ''
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    endpoints: () => ({}),
});